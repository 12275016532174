<template>
	<div class="container">
		<top-header></top-header>
		<div class="operation">
			<div class="width-1300">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/testing' }">产品运行在线监测</el-breadcrumb-item>
					<el-breadcrumb-item>产品运行情况</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="top">
					<div class="select">
						<!-- <el-select v-model="value" placeholder="矿山名称">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
						<el-select v-model="value" placeholder="磨机名称">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select> -->
					</div>
					<div class="search">
						<input type="text" placeholder="请输入矿山名称搜索" v-model="inputValue">
						<div class="btn" @click="btnClick">搜索</div>
					</div>
				</div>
				<data-map :userJson="userJson" v-if="isShow" @maplist="mapClick"></data-map>
				<div>
					<div class="operation-item" @click="gotoinfo(item.id)" v-for="(item,index) in opList.data" :key="index">
						<div class="operation-item-content">
							<div class="mine-img">
								<img :src="$store.state.imaUrl + item.mining_img" alt="">
							</div>
							<p>{{item.name}}</p>
							<p>{{item.company.name}}</p>
							<p>{{item.company.details ? item.company.details : '暂无介绍'}}</p>
							<div class="more">
								<img src="../../../static/images/more.png" >
							</div>
						</div>
					</div>
				</div>
				<!-- 分页 -->
				<div class="page">
					<el-pagination background layout="prev, pager, next" :total="opList.total" small :page-size="12"
						:pager-count="5" @current-change="handleCurrentChange">
					</el-pagination>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	import dataMap from '../../components/map/mapEcharts1.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
			dataMap
		},
		data() {
			return {
				options:[],
				value:'',
				userJson: [],
				opList: [],
				isShow: false,
				inputValue: '',
				page: 1,
			}
		},
		created() {
			this.getList();
		},
		methods: {
			btnClick() {
				this.page = 1;
				this.getList();
			},
			getList() {
				this.isShow = false;
				this.$get('front/mine/get_list', {
					page: this.page,
					num: 12,
					type:1,
					name: this.inputValue
				}).then(res => {
					this.userJson = [];
					this.opList = res.data.data;

					for (let i = 0; i < this.opList.data.length; i++) {
						this.opList.data[i].location = this.opList.data[i].location.split(',');
						this.userJson.push({
							id: this.opList.data[i].id,
							companyname: this.opList.data[i].company.name,
							district: this.opList.data[i].name,
							latitude: this.opList.data[i].location[0],
							longitude: this.opList.data[i].location[1],
							companyimg: this.$store.state.imaUrl + this.opList.data[i].mining_img
						})
					}
          console.log(this.userJson)
          this.isShow = true;
				})
			},
			mapClick(e) {
				this.$router.push({
					query: {
						id: e[0].id
					},
					name: 'operationtree'
				})
				//console.log(e);
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
				this.page = val;
				this.getList();
			},
			gotoinfo(index) {
				this.$router.push({
					query: {
						id: index
					},
					name: 'operationtree'
				})
			}
		}
	}
</script>

<style lang="scss">
	.page {
		text-align: center;
		margin-top: 30px;
	}
	#mapEcharts{
		@media screen and(min-width:320px) and(max-width:767px) {
			display: none;
		}
	}
	.operation {
		margin-top: 62px;
		margin-bottom: 20px;
		min-height: 803px;
		padding-top: 20px;

		@media screen and(min-width:320px) and(max-width:767px) {
			margin-top: 50px;
		}

		.top {
			margin: 30px 0;
			display: flex;
			justify-content: space-between;
			@media screen and(min-width:320px) and(max-width:767px) {
				display: block;
			}
			.select{
				.el-select{
					width:200px;
					&:nth-child(1){
						margin-right: 15px;
					}
					@media screen and(min-width:320px) and(max-width:767px) {
						display: inline-block;
						vertical-align: top;
						width: 48%;
						&:nth-child(1){
							margin-right: 3%;
						}
					}
				}
			}

			.search {
				@media screen and(min-width:320px) and(max-width:767px) {
					margin-top: 20px;
				}
				input {
					border: 0;
					outline: none;
					height: 40px;
					padding: 10px;
					box-sizing: border-box;
					color: #999;
					width: 380px;
					vertical-align: middle;
					@media screen and(min-width:320px) and(max-width:767px) {
						width: 75%;
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
					}
				}

				.btn {
					color: #fff;
					background-color: #00337D;
					height: 40px;
					line-height: 40px;
					padding: 0 20px;
					display: inline-block;
					vertical-align: middle;
					box-sizing: border-box;
					cursor: pointer;
					@media screen and(min-width:320px) and(max-width:767px) {
						width: 25%;
						text-align: center;
						border-top-right-radius: 5px;
						border-bottom-right-radius: 5px;
					}
				}
			}
		}

		&-item {
			display: inline-block;
			text-align: center;
			vertical-align: top;
			width: 23.2444%;
			box-sizing: border-box;
			margin-top: 2.3%;
			margin-right: 2.3%;
			border: 1px solid #e7e7e7;
			padding: 0px 0;
			cursor: pointer;
			min-height: 290px;
			transition: all .4s;
			background-color: #fff;
			// border-radius: 10px;
			overflow: hidden;
			&:hover{
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
			}
			&:nth-child(4n) {
				margin-right: 0;
			}

			&-content {
				position: relative;
				padding-bottom: 20px;
				.mine-img {
					height: 200px;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 100%;
						@media screen and (min-width:320px) and (max-width:767px) {
							width: 100%;
							height: auto;
						}
					}
				}
			}

			@media screen and (min-width:320px) and (max-width:767px) {
				width: 100%;
				margin-right: 0;
				margin-top: 1.2rem;
			}

			p {
				margin-bottom: 15px;
				padding: 0 30px;
				font-size: 14px;
				&:nth-child(2) {
					text-align: center;
					// font-weight: 800;
					margin-top: 15px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				&:nth-child(3) {
					text-align: center;
					// font-weight: 800;
					margin-top: 15px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				&:nth-child(4) {
					text-align: left;
					margin-bottom: 0;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
				@media screen and (min-width:320px) and (max-width:767px) {
					text-align: left;
					padding: 0 20px;
					&:nth-child(3),&:nth-child(4) {
						width: 75%;
						padding-right: 0;
					}
				}
			}
			.more{
				display: none;
				@media screen and (min-width:320px) and (max-width:767px) {
					display: block;
					width: 40px;
					height: 40px;
					position: absolute;
					bottom: 15px;
					right: 20px;
					img{
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
</style>
